<template>
  <div>
    <el-form ref="dataFormRef" :inline="true" :model="dataForm"  @keyup.enter.native="getTableList">
      <el-form-item label="订单号" prop="orderid">
        <el-input
            v-model="dataForm.orderid"
            clearable
            placeholder="请输入订单号"/>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="getTableList">查询</el-button>
        <el-button size="small" @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
        v-loading="dataLoading"
        :data="tableList"
        class="w-100">
      <el-table-column
          prop="transaction_id"
          label="payssion ID">
      </el-table-column>
      <el-table-column
          prop="pm_id"
          label="支付方式">
      </el-table-column>
      <el-table-column
          prop="currency"
          label="货币单位">
      </el-table-column>
      <el-table-column
          prop="order_id"
          label="订单ID">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="支付金额">
      </el-table-column>
      <el-table-column
          prop="paid"
          label="实际支付金额">
      </el-table-column>
      <el-table-column
          prop="net"
          label="最后获得金额">
      </el-table-column>
      <el-table-column
          prop="state"
          label="状态">
      </el-table-column>
      <el-table-column
          prop="fee"
          label="手续费">
      </el-table-column>
      <el-table-column
          prop="refund"
          label="退款金额">
      </el-table-column>
      <el-table-column
          prop="refund_fee"
          label="退款费用">
      </el-table-column>
      <el-table-column
          prop="created"
          label="创建时间">
      </el-table-column>
      <el-table-column
          prop="updated"
          label="更新时间">
      </el-table-column>
      <el-table-column
      fixed="right"
      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">补车</el-button>
      </template>
    </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  queryPayssion,
  ordercallback
} from "@/request/requests";
import {
  MessageError,
  MessageSuccess
} from "@/util/util"
export default {
  name: "Searchorder",
  data() {
    const statusOptions = [
      {
        value: "refunded",
        label: "已退款",
      },
      {
        value: "completed",
        label: "已支付",
      },
      {
        value: "pending",
        label: "未支付",
      },
    ];
    return {
      dataForm:{
        orderid:''
      },
      dataLoading:false,
      tableList:[],
      statusOptions,
      t:""
    }
  },
  methods: {
    resetForm(){
      this.dataForm = {};
      this.tableList = [];
    },
    async getTableList() {
      this.tableList = [];
      this.dataLoading = true;
      let params = {
        ...this.dataForm
      }
      let res = await queryPayssion(params);
      if (res.data&&res.data.result_code === 200) {
        let result = res.data?.transaction;
        result.created = this.$moment(parseInt(result.created)).format('YYYY-MM-DD');
        result.updated = this.$moment(parseInt(result.updated)).format('YYYY-MM-DD');
        result.state 
        this.statusOptions.forEach((r) => {
        if (r.value == result.state) {
          result.state  = r.label;
        }
      });
        this.tableList.push(result);
        this.total = res.data?.total;
      } else {
        MessageError(res.msg);
      }
      this.dataLoading = false;
    },
    handleClick(row){
    if(this.t!=null){
      clearTimeout(this.t)
    }
    let payparams = {
      "order_id":row.order_id,
      "state":"completed"
    }
    this.t = setTimeout(()=>{
    ordercallback(payparams)
		MessageSuccess("完成");
    },500)
    
    }
  },

}
</script>

<style scoped>

</style>